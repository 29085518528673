// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import { createStore, applyMiddleware } from '@modules/redux';
import { createLogger } from '@modules/redux-logger';
import { isDev } from '@root/utils/environment';
import { MLModel } from '@root/cvat-core-wrapper';
import { ModelProvider } from './server';

export interface FunctionsState {
    functions: MLModel[];
    providers: ModelProvider[] | null;
    cvatModels: MLModel[];
}

const initialState: FunctionsState = {
    functions: [],
    providers: null,
    cvatModels: [],
};

enum ActionTypes {
    UPDATE_FUNCTIONS = 'UPDATE_FUNCTIONS',
    UPDATE_PROVIDERS = 'UPDATE_PROVIDERS',
    UPDATE_CVAT_MODELS = 'UPDATE_CVAT_MODELS',
}

export const Actions = {
    updateFunctions: (functions: MLModel[]) => ({
        type: ActionTypes.UPDATE_FUNCTIONS,
        payload: {
            functions,
        },
    }),
    updateProviders: (providers: ModelProvider[]) => ({
        type: ActionTypes.UPDATE_PROVIDERS,
        payload: {
            providers,
        },
    }),
    updateCVATModels: (models: MLModel[]) => ({
        type: ActionTypes.UPDATE_CVAT_MODELS,
        payload: {
            models,
        },
    }),
};

type Action = {
    type: ActionTypes;
    payload: any;
};

const reducer = (state: typeof initialState = initialState, action: Action): typeof initialState => {
    if (action.type === ActionTypes.UPDATE_FUNCTIONS) {
        return {
            ...state,
            functions: [...action.payload.functions],
        };
    }
    if (action.type === ActionTypes.UPDATE_PROVIDERS) {
        return {
            ...state,
            providers: [...action.payload.providers],
        };
    }
    if (action.type === ActionTypes.UPDATE_CVAT_MODELS) {
        return {
            ...state,
            cvatModels: [...action.payload.models],
        };
    }
    return state;
};

export default createStore(
    reducer,
    applyMiddleware(
        createLogger({
            predicate: isDev,
            collapsed: true,
        }),
    ),
);

